/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *  中文专属 seo ！！！
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'react-intl'
import { saInit, saCustom, saAutoTrack } from '../utils/statistics'

interface SEOParams {
  description?: string
  lang?: string
  meta?: Array<{ name: string; content: any }>
  title?: string
  keywords?: string
  saTitle?: string
  isSatitleInit?: boolean
}

const SEO: React.FC<SEOParams> = (props) => {
  const { locale } = useIntl()
  const { description = '', lang = locale, meta = [], title, keywords, saTitle, children, isSatitleInit = true } = props
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )

  useEffect(() => {
    let hm = document.createElement('script')
    hm.src = 'https://hm.baidu.com/hm.js?abe17944fd0c5be6c40530dbb0ea4cfb'
    let s: any = document.getElementsByTagName('script')[0]
    s?.parentNode.insertBefore(hm, s)
  }, [])

  useEffect(() => {
    // 已下三个方法，初始化并开启 sa 全埋点
    // 指标拆解工具 动态修改satitle值完毕后 再进行初始化(isSatitleInit默认为true)
    if (isSatitleInit) {
      saInit()
      saCustom()
      saAutoTrack()
    }
  }, [isSatitleInit])

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          'http-equiv': 'pragma',
          content: 'no-cache',
        },
        {
          'http-equiv': 'cache-control',
          content: 'no-cache',
        },
        {
          'http-equiv': 'expires',
          content: '0',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          name: `sa-title`,
          content: saTitle,
        },
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no`,
        },
        // 添加 og:image 和 og:image:secure_url 解决SEO缩略图问题
        {
          property: `og:image`,
          content: `http://ow-file.sensorsdata.cn/www/logos/sensors_logo.jpg`,
        },
        {
          property: `og:image:secure_url`,
          content: `https://ow-file.sensorsdata.cn/www/logos/sensors_logo.jpg`,
        },
      ].concat(meta)}
    >
      <script src="//res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>
      {children}
    </Helmet>
  )
}

export default SEO
