import React, { useContext, useState, useEffect } from 'react'
import { saTrackLoginAndSignup } from 'utils/statistics'
import { providerContext } from 'components/Provider'
import { isEmpty } from 'lodash'
import { _cookie } from 'utils/statistics'
import { fetchEnLogout } from 'api/user'

const HeaderRender = (props) => {
  const [SensorsHeaderEn, setSensorsHeaderEn] = React.useState<any>(null)
  const { user } = useContext(providerContext)
  const [isLogin, setIsLogin] = useState<boolean>(false)
  useEffect(() => {
    if (user !== null && !isEmpty(user)) {
      setIsLogin(true)
    }
  }, [user])

  const targetPage = (url) => {
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const handleLogin = () => {
    saTrackLoginAndSignup('English_ClickLogin')
    targetPage('/account/login/')
  }
  const handleSign = () => {
    if (!isLogin) {
      saTrackLoginAndSignup('English_ClickSignup', { forward_first_title: document.referrer })
    }
    targetPage(`${process.env.ENV_HOST}/api/client/user/router?demo=Retail`)
  }
  const handleLogout = async () => {
    const res = await fetchEnLogout()
    if (res.data.success) setIsLogin(false)
  }
  React.useEffect(() => {
    import('@eefe/gw-components').then((module) => setSensorsHeaderEn(() => module.SensorsHeaderEn))
  }, [])

  if (!SensorsHeaderEn) {
    return <></>
  }

  return (
    <SensorsHeaderEn
      {...props}
      handleLogin={handleLogin}
      handleSign={handleSign}
      handleLogout={handleLogout}
      isLogin={isLogin}
    />
  )
}

export default HeaderRender
