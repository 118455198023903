
/**
 *  ss-253 咨询台
 *  api文档： https://developer.zhichi.com/pages/246fbc/#%E2%97%8F-%E8%87%AA%E5%AE%9A%E4%B9%89%E5%92%A8%E8%AF%A2%E5%85%A5%E5%8F%A3
 * 
 */

import { useEffect } from 'react';

interface useZhichiChatProps {
    auto_expand?: boolean; // 自动展开选项，可选
    man_trace?: boolean; // 收集用户访问轨迹选项，可选
}

const useZhichiChat = ( config?:useZhichiChatProps ) => {
    const loadScript = () => {
      window.zc = function () {
        window.cbk = window.cbk || [];
        window.cbk.push(arguments);
      };
         window.__frame_load__ = () => {
          const chatContainer = document.getElementById('zc__sdk__container');
          const chatContainerStatus = window.localStorage.getItem('frameStatus') === 'expand' // true为展开
           if (chatContainer && chatContainerStatus) {
            chatContainer.style.zIndex = '998';
          }
         }
      const script = document.createElement('script');
      script.async = true;
      script.id = 'zhichiScript';
      script.src = 'https://sg.sobot.com/chat/frame/v6/entrance.js?sysnum=72d7f5f2bd8649268736617291287cf0';
      document.body.appendChild(script);
    };
  useEffect(() => {
    //   避免重复加载
    !window.zc&&loadScript();
  }, []);

  useEffect(() => {
    if (window.zc) {
      window.zc('config', {
       // auto_expand: !isMb, // true 自动打开,默认不开启
        man_trace: true, // 开启收集用户方访问轨迹 默认不收集 也可在后台直接开启
        ...config, // 允许传入其他配置项
      });
    }
  }, [ config]);
};

export default useZhichiChat;
