import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface ProductCardProps {
  icon?: any
  title?: string
  description?: string
  className?: string
}

const ProductCard: React.FC<ProductCardProps> = ({ className, icon, title, description }) => {
  return (
    <div
      className={classnames(
        styles.card,
        'lg:text-center rounded-[4px]  lg:pt-[30px] lg:h-[254px] lg:px-[36px]   pt-[12px] pb-[16px] px-[18px] whitespace-nowrap',
        className,
      )}
    >
      <p className="lg:block inline-block">
        <img className="w-[50px] h-[50px]" src={icon?.publicURL || icon} alt={title} />
      </p>
      <p className="lg:block align-middle  ml-[28px] lg:ml-0 inline-block leading-[50px] lg:mt-[44px] text-[20px]  lg:text-[24px] text-[#2c2c45] lg:leading-[18px] font-semibold ">
        {title}
      </p>
      <p className="mt-[6px] lg:mt-[20px] text-left lg:text-center  text-[16px] leading-[22px] lg:text-[18px] lg:leading-[24px] text-[#2c2c45] whitespace-normal ">
        {description}
      </p>
    </div>
  )
}

export default ProductCard
