// extracted by mini-css-extract-plugin
export var activeTab = "index-module--activeTab--7NEiK";
export var btn = "index-module--btn--RXUAv";
export var desc = "index-module--desc--rUz+n";
export var evaluationWrapper = "index-module--evaluationWrapper--wLtwq";
export var hidden = "index-module--hidden--bS90R";
export var leftImg = "index-module--leftImg--fqTsp";
export var mbImg = "index-module--mbImg--yl-tf";
export var rightContent = "index-module--rightContent--AVFU5";
export var tab = "index-module--tab--Sxhog";
export var tabs = "index-module--tabs--QA8a8";