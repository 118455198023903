// extracted by mini-css-extract-plugin
export var Carousel = "index-module--Carousel--R62hT";
export var banner = "index-module--banner--h3XaX";
export var bannerBtnsClass = "index-module--bannerBtnsClass--rU48z";
export var bannerClassname = "index-module--bannerClassname--qFdBO";
export var bannerContent = "index-module--bannerContent--dnE7m";
export var bannerDesc = "index-module--bannerDesc--+eqpd";
export var bannerTitle = "index-module--bannerTitle--JgR8a";
export var bannerWrapper = "index-module--bannerWrapper--HuU4S";
export var container = "index-module--container--Bk1qz";
export var customer = "index-module--customer--WK1yE";
export var enTitle = "index-module--enTitle--GZUVD";
export var greenCardContent = "index-module--greenCardContent--sc-vV";
export var logo_wall_en = "index-module--logo_wall_en--1SSbV";
export var mainSection = "index-module--mainSection--ou6JM";
export var pageBottomCardDesc = "index-module--pageBottomCardDesc--hMUIk";
export var section1_title = "index-module--section1_title--uqoHB";