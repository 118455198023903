/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactNode, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { saInit, saCustomEn, saAutoTrack } from 'utils/statistics'

interface SEOParams {
  description?: string
  lang?: string
  meta?: Array<{ name: string; content: any }>
  title?: string
  keywords?: string
  saTitle?: string
  isSatitleInit?: boolean
  children?: ReactNode
}

const SEO: React.FC<SEOParams> = (props) => {
  const { description = '', lang = 'en', meta = [], title, keywords, saTitle, children } = props
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )

  useEffect(() => {
    // 已下三个方法，初始化并开启 sa 全埋点
    // 指标拆解工具 动态修改satitle值完毕后 再进行初始化(isSatitleInit默认为true)
    saInit()
    saCustomEn()
    saAutoTrack()
  }, [])

  const metaDescription = description || site.siteMetadata.description
  // ss-256
  useEffect(() => {
    if (lang === 'en') {
      // 加载 Google Analytics
      const gaScript = document.createElement('script')
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=G-GRC0SGSCRC`
      gaScript.async = true
      document.head.appendChild(gaScript)
      window.dataLayer = window.dataLayer || []
      function gtag() {
        dataLayer.push(arguments)
      }
      gtag('js', new Date())
      gtag('config', 'G-GRC0SGSCRC')
      // 加载 Google Tag Manager
      const gtmScript = document.createElement('script')
      gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-N4MZ3X7D');
    `
      document.head.appendChild(gtmScript)
      // 加载 Google Tag Manager (noscript)
      const gtmNoscript = document.createElement('noscript')
      const iframe = document.createElement('iframe')
      iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-N4MZ3X7D'
      iframe.height = '0'
      iframe.width = '0'
      iframe.style.display = 'none'
      iframe.style.visibility = 'hidden'
      gtmNoscript.appendChild(iframe)
      document.body.appendChild(gtmNoscript)
    }
  }, [lang])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          'http-equiv': 'pragma',
          content: 'no-cache',
        },
        {
          'http-equiv': 'cache-control',
          content: 'no-cache',
        },
        {
          'http-equiv': 'expires',
          content: '0',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          name: `sa-title`,
          content: saTitle,
        },
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no`,
        },
        // 添加 og:image 和 og:image:secure_url 解决SEO缩略图问题
        {
          property: `og:image`,
          content: `http://ow-file.sensorsdata.cn/www/logos/sensors_logo.jpg`,
        },
        {
          property: `og:image:secure_url`,
          content: `https://ow-file.sensorsdata.cn/www/logos/sensors_logo.jpg`,
        },
        // 添加谷歌认证
        {
          name: `google-site-verification`,
          content: `rVt4z9xb0e1FwgkekLCN5hBdVyTXQ-FJ8VJJpuIBqI0`,
        },
      ].concat(meta)}
    >
      <script src="//res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>
      {children}
    </Helmet>
  )
}

export default SEO
