import * as React from 'react'
import Layout from '../layout'
import CookieAuth from 'components/CookieAuth'
import Provider, { User } from 'components/Provider'
import HeaderRender from './HeaderRender'
import FooterRender from './FooterRender'
import { Languages } from 'i18n/config'
import useZhichiChat from 'hooks/useZhichiChat'
// topbanner
import * as styles from './index.module.less'
interface LayoutParams {
  className?: string
  headerType?: string // black | white
  footerClassName?: string
  userUpdate?: boolean
  onUserInfo?: (data: User | null) => void
  hideFooter?: boolean
  header?: any
  headerDemoUrl?: string
  showLangDropdown?: boolean
  [propName: string]: any
  showPhoneCall?: boolean
}

const LayoutEN: React.FC<LayoutParams> = ({
  location,
  children,
  footerClassName,
  userUpdate,
  onUserInfo,
  hideFooter,
  header,
  headerDemoUrl = '/doEbizDemoEnLogin',
  showPhoneCall = true,
  showLangDropdown = false,
}) => {
  // 加载咨询台
  useZhichiChat()
  return (
    <Provider isUpdate={userUpdate} onUserInfo={onUserInfo} lang={Languages.EN}>
      <Layout location={location}>
        {/* Header */}
        {header ? header : <HeaderRender />}
        {/* Body */}
        {/* topbanner */}
        {/* <div className={styles.topbanner}>{children}</div> */}
        {children}
        {/* Footer */}
        {!hideFooter && <FooterRender />}
        <CookieAuth
          content="This site uses cookies to store information on your computer. Some are essential to make our site work; others help us improve the user experience. By using the site, you consent to the placement of these cookies."
          buttonText="AGREE & DISMISS"
        />
      </Layout>
    </Provider>
  )
}

export default LayoutEN
