import React from 'react'
import classnames from 'classnames'
import Button from 'components/Base/Button'

interface TypeCardProps {
  className?: string
  title?: string
  desc?: string
  img_url?: any
  content_list?: {
    icon?: boolean | string
    content: string
  }[]
  reserve?: boolean
  button?: {
    btn_text: string
    btn_href: string
    btn_type: string
  }
}

const TypeCard: React.FC<TypeCardProps> = ({ className, title, desc, img_url, content_list, reserve, button }) => {
  const { btn_text, btn_href, btn_type } = button ?? {}
  return (
    <div
      className={classnames(
        'lg:flex lg:py-[50px] py-[20px] lg:items-center lg:justify-center  ',
        { 'lg:flex-row-reverse': !reserve },
        className,
      )}
    >
      <div>
        <img
          className="lg:h-[432px] lg:w-[640px] shadow-[0_0_20px_0_rgba(153,169,191,0.2)]"
          src={img_url?.publicURL || img_url}
          alt={title}
        />
      </div>
      <div className="lg:w-[70px] h-[20px] lg:h-auto" />
      <div className="lg:w-[491px] text-[#2c2c45]">
        <p className="lg:text-[36px] text-[22px] leading-[34px] font-semibold  lg:leading-[42px] whitespace-nowrap ">
          {title}
        </p>
        <p className="lg:mt-[27px] mt-[20px] text-[16px] leading-[22px] lg:text-[18px] font-medium lg:leading-[26px]">
          {desc}
        </p>
        <ul className="lg:text-[18px] lg:leading-[26px]  text-[16px] leading-[22px]">
          {!!content_list?.length &&
            content_list.map((item) => (
              <li key={item.content} className="mt-[10px]">
                {!!item?.icon && (
                  <img
                    className="w-[15px] h-[15px] inline-block mr-[10px]"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAALVBMVEUFzZYAAAAGzpoDzJUFyJYIzpQEy5QDzJUGzJYFzZIH0JQAw5YA1ZUEy5UEy5QP27HJAAAADnRSTlMzACynGR713VU4JhEMyvivSuQAAACuSURBVBjTYxAEgaOLGLRiwCww34kBBFRg/E0MEKAN4YswwIAjmB8A57MC+SBphAIgfwMSnxvIVwDSHOZpxQ1AmkmQAaSc0+7du3ePJ4A0MAgBybnvQOAmkKXIcABIAqVBCoAsHoYAoO53ENAAtJFhAQMDM5RvwMDAxQA0nh3KLwBaALKVDcpPALLR+ejq0c1Dtw/dPejuRfcPmn/RwwM9vDDDEzO8EfGBJb7Q4xMAatJvTcXG6lQAAAAASUVORK5CYII="
                    alt={item.content}
                  />
                )}
                <span>{item.content}</span>
              </li>
            ))}
        </ul>
        <p className="lg:mt-[50px] text-center mt-[30px] lg:text-left">
          <Button href={btn_href} btnType={btn_type}>
            {btn_text}
          </Button>
        </p>
      </div>
    </div>
  )
}

export default TypeCard
