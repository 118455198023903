import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface dataProps {
  title: string
  desc: string
  content: string
}

interface GreenCardProps {
  className?: string
  dataSource: dataProps
  contentClassName?: string
  titleClassName?: string
  tClassName?: string
}

const GreenCard: React.FC<GreenCardProps> = ({
  className,
  dataSource,
  contentClassName,
  titleClassName,
  tClassName,
}) => {
  const { title, desc, content } = dataSource
  return (
    <div className={classnames(styles.card, 'lg:w-[288px] w-full h-[158px] lg:h-[194px] flex flex-col', className)}>
      <div
        className={classnames(
          'lg:h-[80px] h-[75px] pt-[18px] pl-[20px] lg:pt-[20px] lg:pl-[28px] text-[#fff] card-title',
          tClassName,
        )}
      >
        <h3 className={classnames('text-[18px] font-medium leading-[25px]', titleClassName)}>{title}</h3>
        {!!desc && <p className="text-[14px] leading-[20px]">{desc}</p>}
      </div>
      <p
        className={classnames(
          'lg:pt-[30px] pt-[18px] px-[18px]   lg:px-[30px] text-[14px] text-[#475669] leading-[20px] line-clamp-2',
          contentClassName,
        )}
      >
        {content}
      </p>
    </div>
  )
}

export default GreenCard
