import React, { useState } from 'react'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import { ButtonGroup } from 'components/Base/Button'
import classnames from 'classnames'
import scznyyMb from './img/scznyyMb.png'
import scfxMb from './img/scfxMb.png'
import leftImg from './img/leftImg.png'

import { ScanCodeConsultButton, GartnerWidget, Button } from 'components/index'

interface EvaluationProps {
  descClassName?: string
  data: {
    /**标签 */
    tab: string
    /**左侧底部图片 */
    leftImg?: any
    /**左侧底部移动端图片 */
    leftImgMb?: any
    /**描述 */
    desc: string
    /**按钮文案 */
    btnText?: string
    /**按钮跳转链接 */
    btnLink?: string
    /**按钮扫码 */
    qrcode?: string
    /**GartnerWidget组件所需参数 */
    widget_id?: string
    new_btn_text?: string
    new_btn_link?: string
  }[]
}

const Evaluation: React.FC<EvaluationProps> = (props) => {
  const { data, descClassName } = props
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const handleImgMb = () => {
    if (data[activeIndex].leftImgMb) {
      return data[activeIndex].leftImgMb.publicURL ?? data[activeIndex].leftImgMb
    } else {
      return activeIndex === 0 ? scznyyMb : scfxMb
    }
  }
  const mbStyle: any = {
    '--mbImg': `url(${handleImgMb()})`,
    '--leftImg': `url(${
      data[activeIndex].leftImg ? data[activeIndex].leftImg.publicURL ?? data[activeIndex].leftImg : leftImg
    })`,
  }

  const isMb = useIsWindowWidthSmaller()
  return (
    <div className={styles.evaluationWrapper} style={mbStyle}>
      <div className={styles.leftImg}>
        {data.map((item, index) => {
          return (
            <div
              key={item.widget_id}
              className={classnames({ [styles.hidden]: activeIndex !== index })}
              style={{ position: 'absolute', overflow: 'hidden', right: '15px', top: '12px' }}
            >
              <GartnerWidget widget_id={item.widget_id} />
            </div>
          )
        })}
      </div>
      <div className={styles.rightContent}>
        <div className={styles.tabs}>
          {data.map((item, index) => {
            return (
              <div
                className={classnames(styles.tab, { [styles.activeTab]: activeIndex === index })}
                key={item.tab}
                onClick={() => {
                  setActiveIndex(index)
                }}
              >
                {item.tab}
              </div>
            )
          })}
        </div>
        <div className={classnames(styles.desc, descClassName)}>{data[activeIndex].desc}</div>
        {/* <div className={styles.mbImg}></div> */}
        <img src={handleImgMb()} className={styles.mbImg} />
        <div className={styles.btn}>
          {data[activeIndex].qrcode && (
            <ScanCodeConsultButton
              className="lg:mt-[60px] mt-[3rem] lg:mb-0 mb-[3rem] bg-[#fff] hover:!bg-[#E6FAF5]"
              qrcode={data[activeIndex].qrcode}
              size="small"
              target="_blank"
              buttonText={data[activeIndex].btnText}
              imgBottomText="微信扫码 立即咨询"
              // ghost
              buttonType="primary"
            />
          )}
          {data[activeIndex].btnLink && (
            <Button
              className="lg:mt-[60px] mt-[3rem] lg:mb-0 mb-[3rem]"
              href={data[activeIndex].btnLink}
              target="_blank"
              btnType="primary"
            >
              {data[activeIndex].btnText}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Evaluation
