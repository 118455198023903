import * as React from 'react'
import { NewSensorsFooterEn } from '@eefe/gw-components'
interface FooterRenderProps {
  // className?: string
}
const FooterRender: React.FC<FooterRenderProps> = () => {
  return <NewSensorsFooterEn />
}

export default FooterRender
