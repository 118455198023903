import React from 'react'
import Layout from 'layouts/en'
import { graphql } from 'gatsby'
import { Banner, Button, PageBottomCard } from 'components/index'
import Seo from 'components/EN/Seo'
import Section from 'components/SolutionComponents/Section'
import ProductCard from 'components/ProductAnalytics/ProductCard'
import GreenCard from 'components/Analysis/GreenCard'
import Evaluation from 'components/Features/Evaluation'
import TypeCard from 'components/ProductAnalytics/TypeCard'
import ScrollLogoWall from 'components/EN/ScrollLogoWall'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

import classnames from 'classnames'
import * as styles from './index.module.less'

const ProductAnalytics: React.FC<any> = (props) => {
  const isMb = useIsWindowWidthSmaller()
  const { data } = props
  const { productAnalyticsYaml } = data
  const { title, description, keywords, banner, section1, section2, section3, section4, section5 } =
    productAnalyticsYaml
  const seo = { title, description, keywords }
  return (
    <Layout showPhoneCall={false}>
      <Seo {...seo} />
      <main className={styles.container}>
        <div className={styles.bannerWrapper}>
          <Banner
            {...banner}
            h1tag={true}
            className={styles.banner}
            bannerTitleClassName={styles.bannerTitle}
            bannerDescClassName={styles.bannerDesc}
            bannerBtnsClass={styles.bannerBtnsClass}
            bannerClassname={styles.bannerContent}
          />
        </div>
        {/* Trusted by 2000+ Companies across the World */}
        <Section title={section1?.title} className="lg:px-0 px-[22px] " titleSpanClassName={styles.section1_title}>
          <ScrollLogoWall
            maskVisible={!isMb}
            className={classnames('lg:mt-[30px] mt-[10px] lg:mb-[40px] mx-auto', styles.logo_wall_en)}
            {...section1}
          />
        </Section>
        {/* All You Need to Build a Great Product */}
        <Section
          className="px-[22px] bg-[#F9FAFC] lg:!mt-0 lg:!pt-[50px] !pt-[40px]"
          title={section5?.title}
          titleSpanClassName="lg:!text-[42px] !text-[28px]"
        >
          {section5?.list?.map((item, index) => (
            <TypeCard
              key={item.title}
              {...item}
              className={{ 'lg:bg-[#fff]': index % 2 === 1, 'lg:pb-[100px]': index === 3 }}
            />
          ))}
        </Section>
        {/* More Features to Power Your Product Analytics  */}
        <Section
          title={section2?.title}
          className="bg-[#f9fafc] lg:!mt-0 lg:pt-[50px] lg:pb-[70px] lg:px-0 px-[22px]"
          titleSpanClassName="lg:!text-[42px] !text-[28px]"
        >
          <div className="flex flex-wrap justify-evenly lg:w-[1200px] mx-auto lg:mt-[60px] mt-[20px]">
            {section2?.list?.map((item) => (
              <ProductCard
                key={item.title}
                className={classnames(styles.productCard, 'bg-[#fff] lg:w-[30%] mb-[10px] lg:mb-[30px]')}
                {...item}
              />
            ))}
          </div>
          <div className="lg:mt-[30px] mt-[20px] text-center">
            <Button href={section2.btn.btn_href} target="_blank" btnType={section2?.btn?.btn_type}>
              {section2?.btn?.btn_text}
            </Button>
          </div>
        </Section>
        {/* Why Choose Sensors Data?*/}
        <Section
          title={section3?.title}
          className="lg:px-0 px-[2rem]"
          titleSpanClassName="lg:!text-[42px] !text-[28px] "
        >
          <div className="lg:mt-[60px]  mt-[26px] lg:w-[1200px] mx-auto   lg:flex lg:justify-center ">
            {section3?.dataSource?.map((item) => {
              return (
                <GreenCard
                  className="mt-[14px] lg:mt-0 lg:mx-[15px] lg:!w-[380px] !h-auto pb-[20px]"
                  key={item?.title}
                  dataSource={item}
                  titleClassName={'lg:!text-[24px] !text-[22px] !leading-[26px] '}
                  tClassName="!pt-0 flex items-center"
                  contentClassName={classnames(
                    'lg:!px-[18px] !text-[16px] lg:!text-[18px] line-clamp-3',
                    styles.greenCardContent,
                  )}
                />
              )
            })}
          </div>
        </Section>
        {/* Customer Voice */}
        <Section title={isMb ? '' : section4?.title} titleSpanClassName="lg:!text-[42px] !text-[28px]">
          <div className={classnames('lg:pt-[60px] lg:pb-[60px]', styles.customer)}>
            <Evaluation
              descClassName="!text-[16px] lg:!text-[18px] !leading-[22px] lg:!leading-[26px]"
              data={section4?.data}
            />
          </div>
        </Section>
        <div>
          <PageBottomCard
            title="Ready to Get Started？"
            desc="See how Sensors Data can help you build great product"
            leftButtonText="Contact us"
            leftButtonHref="/form/parade"
            descClassName={styles.pageBottomCardDesc}
            type="newBg"
          />
        </div>
      </main>
    </Layout>
  )
}

export default ProductAnalytics

export const query = graphql`
  query {
    productAnalyticsYaml {
      title
      description
      keywords
      banner {
        title
        desc
        buttons {
          text
          href
          btnType
          ghost
        }
      }
      section1 {
        title
        logo_wall {
          publicURL
        }
      }
      section2 {
        title
        list {
          icon {
            publicURL
          }
          title
          description
        }
        btn {
          btn_text
          btn_href
          btn_type
        }
      }
      section3 {
        title
        dataSource {
          title
          content
        }
      }
      section4 {
        title
        data {
          tab
          leftImg {
            publicURL
          }
          leftImgMb {
            publicURL
          }
          widget_id
          desc
          qrcodeBtn
          btnText
          btnLink
        }
      }
      section5 {
        title
        list {
          title
          desc
          img_url {
            publicURL
          }
          reserve
          content_list {
            content
            icon
          }
          button {
            btn_text
            btn_href
            btn_type
          }
        }
      }
    }
  }
`
